import { styled, Stack, Divider } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { StyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';
import { getUserPreferences } from '@/helpers/utils/localStorage';

interface StyledSamplesDetailsBodyItemContainerProps {
  showProperties: boolean;
}

export const StyledSamplesDetailsBodyItemContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'showProperties'
})<StyledSamplesDetailsBodyItemContainerProps>(({ showProperties }) => ({
  gap: '24px',
  padding: '8px',
  display: 'grid',
  height: '580px',
  overflowX: 'auto',
  transition: '0.2s',
  overflowY: 'hidden',
  animation: `${fadeInAnimation} 0.5s ease`,
  gridTemplateColumns: showProperties ? 'auto 240px' : 'auto 10px',

  '@media (max-height: 790px)': {
    height: '70vh'
  },

  '@media (max-height: 610px)': {
    height: '67vh'
  }
}));

interface StyledSamplesDetailsBodySectionsContainerProps {
  step?: boolean;
  single?: string;
  is_info_retrieval?: string;
  is_only_output_step?: string;
}

export const StyledSamplesDetailsBodySectionsContainer = styled(
  StyledContainer
)<StyledSamplesDetailsBodySectionsContainerProps>(({ step, single, is_info_retrieval, is_only_output_step }) => ({
  gap: '24px',
  padding: '0',
  flexDirection: 'column',
  display: is_info_retrieval === 'true' ? 'flex' : 'grid',
  overflow: is_info_retrieval === 'true' ? 'auto' : 'none',
  paddingBottom: is_info_retrieval === 'true' ? '15px' : 0,
  gridTemplateColumns:
    is_info_retrieval === 'true'
      ? 'none'
      : step
        ? is_only_output_step === 'true'
          ? '49% 49%'
          : '32% 32% 29%'
        : single === 'true'
          ? '98%'
          : '49% 49%'
}));

export const StyledSamplesDetailsBodyItemColumnContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '24px',
  padding: '0',
  width: '100%',
  height: '580px',
  minWidth: '250px',
  overflow: 'hidden',

  '.MuiTypography-h3': { padding: '0 8px', fontWeight: 800, color: theme.palette.grey[600] },

  '@media (max-height: 790px)': {
    height: '70vh'
  },

  '@media (max-height: 610px)': {
    height: '67vh'
  }
}));

export const StyledSamplesDetailsBodyItemTag = styled(StyledText)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '14px',
  padding: '0 8px',
  borderRadius: '4px',
  width: 'fit-content',
  color: theme.palette.grey[600],
  background: theme.palette.grey[200]
}));

export const StyledBodyItemContainer = styled(Stack)({
  overflow: 'unset'
});

export const StyledSamplesDetailsBodyItemColumnContainerContent = styled(StyledContainer)({
  display: 'grid',
  overflow: 'auto',
  paddingRight: 0,
  gap: '24px',
  '& > p': {
    overflow: 'visible'
  }
});

export const StyledPropertiesText = styled(StyledText)(({ theme }) => ({
  flexShrink: 0,
  fontWeight: 700,
  margin: '24px 0 0 0',
  color: theme.palette.grey[700]
}));

interface StyledContentProps {
  property?: string;
  important?: boolean;
  translation?: boolean;
  marking_indexes?: { start: number; end: number; property?: string };
}

export const StyledContent = styled(StyledText)<StyledContentProps>(({ important, translation, theme }) => ({
  padding: '16px',
  fontWeight: 700,
  overflow: 'auto',
  transition: '0.4s',
  borderRadius: '16px',
  wordBreak: 'break-word',
  maxHeight: 'calc(100% - 46px)',
  color: theme.palette.grey[700],
  background: theme.palette.grey[100],
  border: `2px solid ${translation ? theme.palette.primary.main : important ? theme.palette.grey[600] : 'transparent'}`
}));

export const StyledPropertiesContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'row'
})<{ row?: boolean }>(({ row, theme }) => ({
  gap: row ? '16px' : '24px',
  paddingLeft: row ? 0 : '16px',
  height: row ? 'auto' : '580px',
  borderLeft: `1px solid ${row ? 'transparent' : theme.palette.grey[300]}`,

  '.MuiTypography-h3': { padding: '0 8px', fontWeight: 800, overflow: 'initial', color: theme.palette.grey[600] },

  '@media (max-height: 790px)': {
    height: row ? 'auto' : '70vh'
  },

  '@media (max-height: 610px)': {
    height: row ? 'auto' : '67vh'
  }
}));

export const StyledArrowsContainer = styled(Stack)(({ theme }) => ({
  height: '0',
  margin: '0 0 -24px -28px',

  '.MuiSvgIcon-root': {
    fill: theme.palette.grey[500],
    marginTop: '-4px',
    background: getUserPreferences()?.darkMode ? '#383838' : theme.palette.common.white,
    cursor: 'pointer'
  }
}));

export const StyledPropertiesScrollContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'row'
})<{ row?: boolean }>(({ theme, row }) => ({
  paddingBottom: row ? '5px' : '10px',
  paddingRight: row ? 0 : '5px',
  gap: '12px',
  overflow: 'auto',
  flexDirection: row ? 'row' : 'column',

  '.MuiTypography-h3': { padding: '0 8px', fontWeight: 800, overflow: 'initial' },

  '&::-webkit-scrollbar': {
    width: '7px',
    height: row ? '5px' : 'auto'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '8px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.grey[500]
  }
}));

export const StyledRowContainer = styled(Stack)({
  gap: '12px',
  flexDirection: 'row',
  alignItems: 'center'
});

export const StyledTitleDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  width: 'calc(100% - 164px)',
  background: theme.palette.grey[200],
  '@media (min-width:1440px)': {
    width: 'calc(100% - 184px)'
  }
}));

export const PropertyAddCardContainer = styled(StyledContainer)(({ theme }) => ({
  border: `2px solid ${theme.palette.grey[300]}`,
  cursor: 'pointer',
  background: 'transparent',
  maxWidth: '163px',
  minWidth: '163px'
}));

export const PropertiesAddCardText = styled(StyledText)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.grey[400]
}));

export const StyledExpandMore = styled(ExpandMore)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey[300]
}));

export const StyledExpandLess = styled(ExpandLess)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey[300]
}));
