import React, { useEffect, useState } from 'react';

import { StyledCheckbox, StyledLoader } from '@/components/lib';
import { StyledUpdatePropertyPopover } from '../../SampleDetailsDialogBodyCard.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { PropertyType, retrieveAggregatedPropertiesByFilter } from '@/helpers/services/api';

interface SamplesDetailsPropertyValueProps {
  name?: string;
  kind: PropertyType;
  popupAnchor: HTMLElement | null;
  curValue: string | number | string[];
  handleUpdateCustomProperty: (val: string[]) => void;
  setPopupAnchor: (anchor: HTMLElement | null) => void;
}

export const SamplesDetailsPropertyCategoricalPopOver = (props: SamplesDetailsPropertyValueProps) => {
  const { name, kind, curValue, popupAnchor, setPopupAnchor, handleUpdateCustomProperty } = props;
  const { versionId, type } = getAppStorage();

  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [categoricalValue, setCategoricalValue] = useState<string[]>(Array?.isArray(curValue) ? curValue : []);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  const handleClosePopover = () => {
    setPopupAnchor(null);
    handleUpdateCustomProperty(categoricalValue);
  };

  useEffect(() => {
    if (popupAnchor) {
      setIsCategoriesLoading(true);
      retrieveAggregatedPropertiesByFilter({ application_version_id: versionId, environment: type }).then(res => {
        const possibleValues = (res as never)?.[kind]?.[`${name}`];

        setOptions(
          possibleValues
            ? Object.keys(possibleValues)?.map(val => {
                return { value: val, label: val };
              })
            : []
        );
        setIsCategoriesLoading(false);
      });
    }
  }, [popupAnchor]);

  return (
    <StyledUpdatePropertyPopover anchorEl={popupAnchor} open={Boolean(popupAnchor)} onClose={handleClosePopover}>
      {isCategoriesLoading ? (
        <StyledLoader />
      ) : (
        <StyledCheckbox options={options} state={categoricalValue} setState={setCategoricalValue} withSelectAll />
      )}
    </StyledUpdatePropertyPopover>
  );
};
